body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bg-grid-pattern {
  background-image: radial-gradient(circle at 1px 1px, rgb(0 0 0 / 0.2) 1px, transparent 0);
  background-size: 40px 40px;
}




/* Navbar.css */
.robot {
  position: absolute;
  bottom: 30px;
  transition: transform 0.5s ease-in-out;
}

.move-to-button {
  transform: translate(0, -40px);
}

@keyframes robotMovement {
  0% {
    transform: translateX(-100px);
  }
  50% {
    transform: translateX(150px);
  }
  100% {
    transform: translateX(0);
  }
}

.robot {
  position: absolute;
  top: -5px;  /* Adjust the vertical position */
  left: 120%;  /* Place robot near the "Try Now" button */
  transition: transform 0.5s ease, left 0.5s ease;
}

button:hover + .robot {
  transform: translateX(-25px);  /* Move the robot towards the button */
  left: 90%;  /* Adjust the left position to animate towards the button */
}





@tailwind base;
@tailwind components;
@tailwind utilities;
